@media (min-width: 820px) {
  .boxOne {
    padding-right: 1rem;
  }
  .boxTwo {
    padding-right: 1rem;
  }
}

.formUserCell {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 6rem;
}

.formScreenCell {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  width: 50%;
}

.formUserCellAlt {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.programsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-right: 2rem;
}

.inputContainer {
  padding-top: 0.5rem;

  .formRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4rem;
    padding-right: 2rem;
  }

  .formScreenRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding-right: 2rem;
  }

  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    padding-right: 2rem;
  }

  .box {
    flex-grow: 1;
    justify-content: end;
  }

  .boxOne {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 1rem;
  }

  .boxTwo {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 1rem;
  }

  .boxThree {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .boxFour {
    display: flex;
    align-items: center;
  }

  .city {
    min-width: 6rem;
  }

  .state {
    min-width: 6rem;
  }

  .zip {
    min-width: 6rem;
  }

  .programs {
    min-width: 6rem;
  }

  .remindersInputContainer {
    align-content: center;
  }
}

.customDatePickerInput .MuiInputBase-root {
  max-height: 1.4rem; /* Set your desired max height */
  height: 1.4rem; /* Explicitly set the height as well */
}
