.flexContainer {
  display: flex;
  flex-wrap: wrap;
  padding-right: 2rem;
}

.box {
  flex-grow: 1;
  justify-content: end;
}

.boxOne {
  display: flex;
  align-items: center;
  justify-content: start;
}

.boxTwo {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 0;
}

.checkboxContainer {
  display: flex;
  align-items: center;

  .isADriver {
    align-items: center;
  }
}

.formDriverCell {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 6rem;
  justify-content: start;
}

.inputContainer {
  .dateContainer {
    display: flex;
    padding-top: 0.4rem;
    margin-bottom: 0.4rem;

    .dateCell {
      margin-top: auto;
      margin-bottom: auto;
      width: 14rem;
    }

    .customDatePickerWidth,
    .customDatePickerWidth > div.react-datepicker-wrapper,
    .customDatePickerWidth
      > div
      > div.react-datepicker__input-container
      .customDatePickerWidth
      > div
      > div.react-datepicker__input-container
      input {
      width: 100%;
      padding-left: 5px;
      padding-right: 2rem;
    }
  }
}
