@import url("https://fonts.googleapis.com/css?family=Poppins");

.navbar {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;
}

.buttonContainer {
  padding: 10px;
  width: 100%;
}

.firstButtonContainer {
  padding: 10px 10px 10px 0px;
  width: 100%;
}
