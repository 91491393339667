.inputContainer {
  .dateContainer {
    display: flex;
    padding-top: 0.4rem;

    .dateCell {
      margin-top: auto;
      margin-bottom: auto;
      width: 14rem;
    }

    .customDatePickerWidth,
    .customDatePickerWidth > div.react-datepicker-wrapper,
    .customDatePickerWidth
      > div
      > div.react-datepicker__input-container
      .customDatePickerWidth
      > div
      > div.react-datepicker__input-container
      input {
      width: 100%;
      padding-left: 5px;
      padding-right: 2rem;
    }
  }

}

.trainingCell {
  min-width: 12rem;
  margin-top: auto;
  margin-bottom: auto;
}

.courseInputContainer {
  .formRow {
    display: flex;
  }
}
