@import url("https://fonts.googleapis.com/css?family=Poppins");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.login {
  width: calc(100vw);
  height: calc(100vh);
}

.loginBackground {
  z-index: 1;
  width: 100vw;
  background-image: url("./loginRhino.svg");
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  position: relative;
}

.test {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 50vh;
  border: 1px solid;
  border-radius: 5px;
  opacity: 0.9;
  border-color: #21407b;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}

.formItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.resetItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.loginButtonContainer {
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formItemTop {
  margin-bottom: 15px;
}

.passwordItemTop {
  text-align: center;
  margin-bottom: 15px;
}

.formItemBottom {
  margin-top: 15px;
}

.formInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#linscot {
  background: linear-gradient(180deg, #21407b 53.65%, #4abeff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 1080px) {
  .loginBackground {
    background-image: none;
  }

  .test {
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 60%;
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-color: #21407b;
    background-color: white;
  }
}
