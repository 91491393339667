.deleteCell {
  margin: auto 4rem auto 4rem;
  text-align: center;
  justify-content: center;
}

#deleteOrCancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
