.formCell {
  width: 14rem;
  margin: auto;
}

.inputContainer {
  padding-top: 0.5rem;

  .formRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    padding-right: 2rem;
  }

  .deleteRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
