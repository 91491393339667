@import url("https://fonts.googleapis.com/css?family=Poppins");
.app {
  background-color: #f9fafb;
  height: calc(95vh);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.home {
  margin: 15px;
}

.tableMenuStart {
  align-content: center;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;
  color: #21407b;
  font-weight: bold;
  font-size: 18px;
}

.tableMenuButton {
  display: flex;
  align-content: center;
  justify-content: center;
}

#table {
  height: calc(80vh);
}

#operationsTable {
  height: calc(80vh);
  display: flex;
  flex-direction: row;
}

#tableMenuColumn {
  width: 280px;
}

#search {
  width: 50%;
  padding-left: 10px;
}

#navBar {
  width: 50%;
  padding-right: 10px;
}

#userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#linscot {
  background: linear-gradient(180deg, #21407b 53.65%, #4abeff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
