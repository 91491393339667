@import url("https://fonts.googleapis.com/css?family=Poppins");
#tableToolBar {
  display: flex;
  flex-direction: row;
}

.tooltipFormBox {
  display: flex;
  flex-direction: column;
  width: calc(94cqw);
  height: calc(76cqh);
  overflow: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px 20px 20px 20px;
}

.tooltipFormRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-right: 20px;
}

.tooltipFormHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tooltipFormCell {
  width: 100px;
  margin: auto;
}

.tooltipCSZRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-right: 20px;
  justify-content: flex-start;
}

.tooltipReminderRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-right: 20px;
  justify-content: space-between;
}

.tooltipUSHAZRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: center;
}

.tooltipCSZCell {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  min-width: 300px;
}

.tooltipDueCell {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  min-width: 220px;
}

.tooltipZip {
  display: flex;
  flex-direction: row;
  min-width: 250px;
  margin-right: 20px;
}

.tooltipDueText {
  text-align: start;
  margin-left: 0;
  padding-right: 20px;
}

.tooltipZipInput {
  width: 100px;
  text-align: start;
  margin: auto;
  margin-left: 0;
  padding-right: 20px;
}

.tooltipUSHAZInput {
  width: 100px;
  text-align: start;
  margin: auto;
}

.tooltipUSHAZCell {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  width: 220px;
}

#saveOrCancel {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
  padding-right: 20px;
}

.employeeRow {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-right: 20px;
  justify-content: flex-start;
}

.tooltipNames {
  width: 40%;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.tooltipCSD {
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.tooltipConsortiumCell {
  width: 6rem;
  margin: auto 10px auto auto;
}

.tooltipNamesCell {
  width: 114px;
  margin: auto;
}

.tooltipAttendeesCell {
  width: 5rem;
  margin: auto 0 auto 0;
}

.tooltipDateCell {
  width: 5.5rem;
  margin: auto 0 auto 0;
}

.tooltipTitleInstructorRow {
  max-width: 60%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-right: 20px;
}

.lastModified {
  opacity: 50%;
  margin-top: 15px;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  padding-left: 5px;
}
